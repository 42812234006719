.widget {
    padding: 6rem 0;

    .w-title {
        color: $rd;
        font-weight: 700;
    }

    .e-subtitle {
        display: block;
        font-weight: 300;
    }

    .w-list {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    * + .w-header,
    * + .w-content,
    * + .w-footer {
        margin-top: 2rem;
    }
}
