.e-link {
    display: block;
    margin: 0 auto;
    position: relative;
    text-decoration: none;
}

/** Course */
.e-link-cientific-meeting,
.e-link-course,
.e-link-group,
.e-link-knowledge,
.e-link-report,
.e-link-tratament {
    border-color: $gray-300;
    border-radius: $border-radius;
    border-style: solid;
    border-width: 1px;
    display: flex;
    flex-direction: column;
    max-width: 20rem;
    min-height: 100%;
    overflow: hidden;
    transition-duration: 0.2s;
    transition-property: border;
    transition-timing-function: ease;

    .e-thumb {
        background-color: $rd;
        border-bottom-color: $gray-300;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-radius: 0;
        padding-bottom: 30%;
        transition-duration: 0.2s;
        transition-property: border;
        transition-timing-function: ease;
    }

    .e-thumb-user {
        border-width: 3px;
        box-shadow: none;
        margin: -3rem auto;
        height: 6rem;
        position: relative;
        width: 6rem;
    }

    .e-body {
        background-color: $light;
        flex-grow: 1;
        padding: 4rem 1rem 1rem;
    }

    .e-title {
        font-size: 1.125rem;
    }

    .e-meta {
        border-top-color: $rd;
        border-top-style: solid;
        border-top-width: 1px;
        font-size: 0.875rem;
        margin-top: 0.5rem;
        padding-top: 0.5rem;
    }

    &:focus,
    &:hover {
        border-color: $rd;

        .e-thumb {
            border-color: $rd;
        }
    }

    .e-price {
        border-top-color: $st;
        border-top-style: solid;
        border-top-width: 1px;
        margin-top: 0.5rem;
        text-align: center;
    }

    .e-footer {
        background-color: $light;
        display: flex;
        padding: 0 0.75rem 0.75rem;

        .btn {
            flex-grow: 1;
            margin: 0.25rem;
        }
    }
}

/** User */
.e-link-user {
    display: block;
    padding-top: 1rem;
    position: relative;

    .e-role {
        box-shadow: 0 0 20px #adb5bd;
        border-radius: 3rem;
        background-color: #fff;
        font-size: 1.25rem;
        height: 3rem;
        left: 50%;
        line-height: 1;
        padding: 0.75rem;
        position: absolute;
        text-align: center;
        transform: translateX(40%) translateY(-110%);
        width: 3rem;

        .icon-gold {
            color: $yellow-500;
        }
    }

    .e-body {
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
    }

    &:focus .e-thumb-user,
    &:hover .e-thumb-user {
        box-shadow: 0 0 20px $gray-800;
    }
}

/** File */
.e-link-file {
    background-color: $white;
    border-color: $gray-300;
    border-style: solid;
    border-width: 1px;
    color: $dark;
    display: block;
    padding: 0.5rem;

    .e-link {
        color: $dark;
    }

    .row {
        align-items: center;
    }

    &:focus,
    &:hover {
        background-color: $light;
    }
}

.e-link-classroom {
    @extend .e-link-file;

    .fa-check-circle {
        color: $success;
    }

    .fa-times-circle {
        color: $danger;
    }
}

/** Chat */
.e-link-chat {
    background-color: transparent;
    border-radius: $border-radius;
    color: $st;
    padding: 0.5rem;
    position: relative;
    transition-property: background;
    transition-duration: 0.3s;
    transition-timing-function: ease;

    &.unread::before {
        background-color: $danger;
        border-radius: 0.25rem;
        content: "";
        height: 0.5rem;
        position: absolute;
        left: 3rem;
        width: 0.5rem;
    }

    .e-thumb-user {
        border-width: 2px;
        height: 3rem;
        margin: 0;
        width: 3rem;
    }

    .e-excerpt {
        color: $dark;
        font-size: 0.875rem;
        font-style: italic;
    }

    .row {
        align-items: center;
    }

    &:focus,
    &:hover {
        background-color: rgba($nd, 0.5);
    }
}

/** Forum */
.e-link-forum {
    border-top-color: $gray-300;
    border-top-style: solid;
    border-top-width: 1px;
    margin-top: 0.5rem;
    padding: 1rem;

    .e-link-meta {
        @extend .nav;

        margin-top: 1rem;
    }

    .e-meta {
        @extend .nav-link;

        border-top-color: $rd;
        border-top-style: solid;
        border-top-width: 1px;
        padding-bottom: 0;
        padding-left: 0;
        padding-top: 0;
    }

    &:focus,
    &:hover {
        background-image: linear-gradient(0deg, $white 0%, $gray-300 100%);
    }
}

/** Gallery */
.e-link-gallery {
    background-size: cover;
    background-position: center;
    display: block;
    height: 4.375rem;
    margin: 0.5rem;
    width: 7.375rem;
}

/** Article */
.e-link-article {
    border-color: $st;
    border-radius: 1rem;
    border-style: solid;
    border-width: 1px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 1rem);
    margin-top: 1rem;
    padding: 1rem;

    .e-header {
        align-items: center;
        display: flex;
        flex-grow: 1;
        font-weight: 700;
        text-transform: uppercase;
    }

    .e-body {
        margin-top: 1rem;
    }

    .e-footer {
        margin-top: 1rem;
    }

    .btn-group {
        width: 100%;
    }
}
