.breadcrumb {
    background-color: transparent;
    display: flex;
    font-size: 0.875rem;
    margin: 1rem 0 0;
}

.breadcrumb-item + .breadcrumb-item::before {
    color: $rd;
    content: "\f101";
    font-family: "Font Awesome 5 Free";
    font-size: 0.75rem;
    font-weight: 900;
    margin-right: 0.5em;
    margin-top: 0.15rem;
    padding: 0;
}

.breadcrumb a {
    color: $st;
    text-decoration: none;

    &:focus,
    &:hover {
        text-decoration: underline;
    }
}
