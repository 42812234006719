.w-featured {
    background-image: url('../images/bg-featured.png');
    background-size: cover;
    background-position: center;
    padding: 1rem 0;

    @include media-breakpoint-up(sm) {
        padding: 8rem 0;
    }

    .w-row {
        align-items: center;

        @include media-breakpoint-up(md) {
            flex-direction: row-reverse;
        }
    }

    .w-header {
        font-weight: 700;
        text-align: center;

        .e-title {
            color: $st;
            font-size: 2rem;
            font-weight: 700;
            text-transform: uppercase;

            @include media-breakpoint-up(sm) {
                font-size: 4rem;
            }
        }

        .e-subtitle {
            color: $st;
            font-size: 1.5rem;
            font-weight: 700;
            margin-top: 2rem;
        }
    }

    .w-content {
        margin-top: 2rem;

        @include media-breakpoint-up(xl) {
            .form-group:first-of-type {
                margin-top: 0;
            }
        }
    }

    .p-form {
        padding: 0;
    }
}
