.e-thumb {
    background-color: $white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 1rem;
    box-shadow: 0 9px 20px $gray-500;
    height: 0;
    overflow: hidden;
    padding-bottom: 65%;
}


.e-thumb-user {
    background-color: $light;
    background-image: url('../images/default-user.png');
    background-position: center;
    background-size: cover;
    border-color: $white;
    border-radius: 3.5rem;
    border-style: solid;
    border-width: 0.5rem;
    box-shadow: 0 0 20px $gray-500;
    height: 7rem;
    margin: 1rem auto 0.5rem;
    overflow: hidden;
    transition-duration: 0.3s;
    transition-property: box-shadow;
    transition-timing-function: ease;
    width: 7rem;
}
