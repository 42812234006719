.e-content {
    font-size: 1.25rem;
    font-weight: 300;
    margin-left: auto;
    margin-right: auto;
    max-width: 38.750rem;

    a {
        color: $rd;
        text-decoration: none;

        &:focus,
        &:hover {
            text-decoration: underline;
        }
    }

    p,
    ol,
    ul,
    blockquote {
        line-height: 1.5;
    }

    h2,
    h3,
    h4,
    h5 {
        &::before {
            background-color: $rd;
            content: "";
            display: inline-block;
            height: 2px;
            margin-bottom: -5px;
            margin-right: 0.1em;
            width: 1.5em;
        }
    }

    h2 {
        color: $rd;
        font-size: 1.5rem;
        font-weight: 700;
    }

    h3,
    h4,
    h5 {
        color: #000;
        font-weight: 300;
        font-size: 1.125rem;
        margin-top: 1em;
    }

    h3 {
        font-size: 1.25rem;
    }

    ul {
        padding-left: 1em;
    }

    ul li {
        list-style-type: none;

        &::before {
            color: $st;
            content: "\f105";
            font-family: "Font Awesome 6 Free";
            font-size: 0.75em;
            font-weight: 900;
            margin-right: 0.5rem;
        }
    }

    * + p,
    * + h2,
    * + h3,
    * + h4,
    * + h5,
    * + ol,
    * + ul,
    * + blockquote {
        margin-top: 1em;
    }
}

* + .e-content {
    margin-top: 2em;
}

.e-content-user .card {

    & + .card {
        margin-top: 2rem;
    }
}
