@import '~bootstrap/scss/functions';

$st: #2f40b1;
$nd: #4cc3d9;
$rd: #8238c3;
$th: #717171;

$st-dark: darken(#2f40b1, 10%);
$nd-dark: darken(#4cc3d9, 10%);
$rd-dark: darken(#8238c3, 10%);
$th-dark: darken(#717171, 10%);

$fb: #4267b2;
$go: #db4437;

$primary: $st;
$secondary: $nd;

$colors: (
    primary: $st,
    secondary: $nd,
);

$font-family-base: 'Outfit', sans-serif;
$border-radius: 0.5rem;
