.w-comment {

    .reply-area {
        @extend .alert;
        @extend .alert-info;

        margin: 0.5rem 1rem;
        position: relative;
    }

    .btn-remove {
        position:absolute;
        right: 0.5rem;
    }

    .reply-area {
        display: none;

        &.active {
            display: block;
        }
    }
}
