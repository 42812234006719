.c-table {
    table-layout: fixed;

    th {
        text-align: center;
    }
}

.c-day {
    padding-bottom: 100%;
    position: relative;
}

.c-holder {
    height: 100%;
    position: absolute;
    width: 100%;
}
