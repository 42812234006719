.e-message {
    margin-top: 1rem;
    outline: none;
    padding: 0.25rem 1rem 0.25rem 3.75rem;
    position: relative;
    width: 100%;

    &.sequence {
        margin-top: 0;
    }

    .message {
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 0.3rem;
        box-shadow: 2px 2px 3px rgba($dark, 0.4);
        color: #333;
        display: inline-block;
        margin-right: 2rem;
        max-width: 37.5rem;
        min-width: 6rem;
        overflow: hidden;
        padding: 0.2rem 0.5rem 1rem;
        position: relative;
        text-align: left;
        vertical-align: middle;
        width: auto;
        word-break: break-word;
    }

    .e-thumb {
        border-color: $white;
        border-radius: 3.5rem;
        border-style: solid;
        left: 0;
        position: absolute;
    }

    .m-sender {
        color: $nd;
        display: block;
        font-weight: 700;
        text-decoration: none;
    }

    .m-action {
        background-color: #fff;
        box-shadow: 0 0 6px 6px #fff;
        font-size: 0.875rem;
        opacity: 0;
        padding: 0 0.2rem;
        position: absolute;
        right: 0;
        top: 0;
        transition-duration: 0.2s;
        transition-timing-function: ease;
        transition-property: opacity;
        z-index: 1;

        .btn {
            color: $nd;
            padding: 0;
            margin: 0;
        }
    }

    .m-link {
        color: $dark;
        background-color: rgba($nd, 0.2);
        border-radius: 0.3rem;
        display: block;
        font-size: 0.875rem;
        padding: 0.1rem 0.5rem;

        &:focus,
        &:hver {
            background-color: rgba($nd, 0.4);
            text-decoration: none;
        }
    }

    .m-date {
        bottom: 0.1rem;
        font-size: 0.625rem;
        opacity: 0.8;
        position: absolute;
        right: 0.5rem;
        transition-duration: 0.2s;
        transition-timing-function: ease;
        transition-property: opacity;
    }

    .m-attachment {
        color: $dark;

        .fa-solid{
            font-size: 3em;
        }
    }

    .m-attachment-image {
        display: block;
        color:#fff;

        .fa-solid{
            font-size: 3em;
            left: 50%;
            position: absolute;
            opacity: 0;
            text-shadow: 2px 2px 3px #000;
            top: 50%;
            transform: translate(-50%,-50%);
        }

        &:focus .fas,
        &:hover .fa-solid{
            opacity: 0.8;
        }
    }

    .m-erased {
        font-style: italic;
    }

    .ratio {
        width: 11rem;
    }

    .ratio > * {
        background: $dark;
    }

    &.me {
        text-align: right;

        .message {
            background-color: #c9edf4;
            margin-left: 2rem;
            margin-right: 0;
        }

        .m-action {
            background-color: #c9edf4;
            box-shadow: 0 0 6px 6px #c9edf4;

            .btn {
                color: $dark;
            }
        }

        .e-thumb {
            display: none;
        }
    }

    &:focus {
        background-color: rgba($nd, 0.5);
    }

    &:focus .m-action,
    .message:hover .m-action,
    .message:hover .m-date {
        opacity: 1;
    }
}

