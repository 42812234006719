.alert {
    margin: 1rem 0;
    padding: 0.5rem 3rem 0.5rem 0.5rem;

    p {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 1140px;
        padding: 0 15px;
    }

    .btn-sm {
        position: absolute;
        right: 0.5rem;
        top: 0.2rem;
    }
}

.alert .errors ul {
    margin: 0;
    padding: 0;
}

.alert .errors li {
    list-style: none;
}
