.p-header {
    background-image: linear-gradient(0deg, $white 0%, $gray-200 100%);
    border-top-color: $rd;
    border-top-style: solid;
    border-top-width: 2px;
    color: $st;
    padding-bottom: 1rem;
    text-align: center;

    .e-count {
        font-size: 0.875rem;
        margin-top: 1rem;
    }

    .p-title {
        font-weight: 200;
        margin-top: 2rem;
    }

    .p-subtitle {
        display: block;
        font-size: 1.5rem;
        margin-top: 0.5rem;
        padding-top: 0.25rem;
        position: relative;

        &::before {
            background-color: $st;
            content: "";
            height: 1px;
            left: 50%;
            position: absolute;
            top: 0;
            transform: translateX(-50%);
            width: 4rem;
        }
    }
}

.p-header-user {
    @extend .p-header;

    text-align: left;

    .row {
        justify-content: center;
    }

    .e-role {
        margin-top: 0.5rem;
    }
}

.p-header-group {
    .e-title {
        text-align: left;
    }
}
