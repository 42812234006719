.dropdown-menu {
    border-color: $st;
    overflow: hidden;
    padding: 0;

    a {
        color: $st;

        &:focus,
        &:hover {
            background-color: $st;
            color: $white;
        }
    }
}
