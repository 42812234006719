/** Apresentação do curso */
.w-course-presentation {
    padding: 2rem;
}

/** Dados do curso */
.w-course-data {
    padding: 2rem;

    .nav {
        justify-content: center;
    }

    .list-unstyled li + li {
        margin-top: 0.5rem;
    }
}
