.w-socilaser {
    background-color: $light;
    background-image: linear-gradient(to right, rgba(30, 67, 86, 0.8),
        rgba(30, 67, 86, 0.6)),
        url("../images/hero-bg.jpg");
    background-size: cover;
    background-position: center;
    color: $white;

    .row {
        align-items: flex-end;
    }

    .w-title {
        color: $white;
    }
}
