.e-comment {
    border-top-color: $gray-300;
    border-top-style: solid;
    border-top-width: 1px;
    display: block;
    padding: 0.5rem 1rem;

    &:focus {
        background-color: $light;
    }

    .e-date {
        color: $rd;
        font-size: 0.875rem;
        margin-top: 0.5em;
        text-align: right;
    }

    .e-thumb-user {
        border-width: 2px;
        height: 3rem;
        width: 3rem;
    }

    .col {
        align-items: stretch;
        justify-content: space-between;
        flex-direction: column;
        display: flex;
    }

    .reply {
        @extend .alert;
        @extend .alert-info;
        @extend .btn;

        text-align: left;
    }
}

