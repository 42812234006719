.table {
    margin-bottom: 0;
    margin-top: 1rem;
}

.table thead th {
    border-top: none;
}

.table td,
.table th {
    vertical-align: middle;
    white-space: nowrap;
}

.table tr[data-action="modal"] {
    cursor: pointer;
}

.table tr[data-action="collapse"] + tr:focus,
.table tr[data-action="collapse"] + tr:hover {
    background-color: transparent;
}

.table th {
    cursor: pointer;
    position: relative;
}

.table th[data-sort]::after,
.table th[data-sort]::before {
    content: "\f101";
    font-family: "Font Awesome 5 Free";
    padding: 0;
    right: 1rem;
    position: absolute;
}

.table th[data-sort="asc"]::after {
    content: "\f0d7";
}

.table th[data-sort="desc"]::after {
    content: "\f0d8";
}

.table-sale td {
    border-color: transparent;
}

.table .input-group {
    flex-wrap: nowrap;
    min-width: 8rem;
}

.table-bordered {
    background-color: $white;
    border-radius: $border-radius;
}

/** Plan */
.table-plan {
    margin-top: 1rem;
}
.table-plan th .fa-solid{
    color: $rd;
    display: block;
    font-size: 2em;
}
.table-plan td:first-child {
    text-align: left;
}
.table-plan .fa-check {
    color: $success;
}
.table-plan .fa-check-double {
    color: $warning;
}
.table-plan .fa-minus {
    color: $danger;
}
