.gallery {
    background-color: rgba($white, 0.8);
    display: none;
    height: 100%;
    left: 0;
    margin: 0;
    padding: 1rem;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;

    &.active {
        display: block;
    }

    * + .gallery-header,
    * + .gallery-content,
    * + .gallery-footer {
        border-top-color: $st;
        border-top-style: solid;
        border-top-width: 1px;
    }
}

.gallery-body {
    background-color: $white;
    border-color: $st;
    border-radius: 1rem;
    border-style: solid;
    border-width: 1px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    overflow: hidden;
    width: 100%;
}

.gallery-header {
    align-items: flex-start;
    color: $st;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
}

.gallery-title {
    font-weight: 300;
}

.gallery-content {
    flex-grow: 1;
    overflow: auto;
    padding: 1rem;
}

.gallery-footer {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
}

.e-list-gallery {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 0;
    max-width: 100%;
    padding: 0;

    .e-list-item {
        width: 15rem;
        padding: 0.5rem;
        width: 15rem;
    }
}

.e-gallery {
    border-color: transparent;
    border-radius: 0.65rem;
    border-style: solid;
    border-width: 2px;
    display: block;
    position: relative;
    text-decoration: none;

    .e-thumb {
        background-color: $light;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 0.5rem;
        height: 0;
        padding-bottom: 60%;
        width: 100%;

        .fa-solid {
            color: $white;
            font-size: 2rem;
            opacity: 0.2;
            position: absolute;
            right: 0.5rem;
            top: 0.5rem;
            text-shadow: 2px 2px 3px rgba($dark, 0.2);
        }
    }

    .e-title {
        background-color: $dark;
        border-radius: 0.5rem;
        bottom: 100%;
        color: $white;
        display: none;
        margin: 0.25rem;
        padding: 0.2rem 0.5rem;
        position: absolute;

        &::after {
            border-color: transparent;
            border-style: solid;
            border-top-color: $dark;
            border-width: 10px;
            content: "";
            left: 1rem;
            position: absolute;
            top: 100%;
        }
    }

    &::after {
        font-family: 'Font Awesome 6 Free';
        font-size: 1.5rem;
        font-weight: 900;
        left: 0.5rem;
        position: absolute;
        top: 0.5rem;
        text-shadow: 0 0 10px $white;
    }

    &[data-select="true"]::after {
        content: "\f058";
    }

    &:focus,
    &:hover {
        border-color: $st;

        .e-title {
            display: inline-block;
        }
    }
}
