.w-certificate {

    img {
        border-color: $white;
        border-radius: 2rem;
        border-style: solid;
        border-width: 4px;
        display: block;
        margin: 0 auto;
        box-shadow: 0 0 30px rgba(0,0,0,0.4);

        @include media-breakpoint-down(lg) {
            margin-top: 2rem;
        }
    }

    .row {
        align-items: center;
        justify-content: center;
    }

    .w-footer {
        text-align: center;

        .btn {
            display: inline-block;
            margin-top: 1rem;
            padding-left: 3rem;
            padding-right: 3rem;
        }
    }
}
