/** Top */
.nav-top {
    border-color: transparent;
    border-bottom-color: $rd;
    border-bottom-right-radius: 0.5rem;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-right-color: $rd;
    border-right-style: solid;
    border-right-width: 1px;
    display: flex;
    justify-content: center;
    margin: 0 1rem;
    z-index: 10;

    @include media-breakpoint-down(sm) {
        .txt-hide {
            display: block;
            height: 1px;
            margin: -1px;
            overflow: hidden;
            position: absolute;
            width: 1px;
        }
    }

    @include media-breakpoint-up(lg) {
        font-size: 0.875rem;
        position: absolute;
        right: 0;
        top: 0;

        .nav-item:last-child() .nav-link {
            border-bottom-right-radius: 0.5rem;
        }
    }

    .nav-link {
        padding: 0.2rem 0.5rem;
        position: relative;

        &.unread::before {
            background-color: $danger;
            border-radius: 0.25rem;
            content: "";
            height: 0.5rem;
            position: absolute;
            left: 1.25rem;
            width: 0.5rem;
        }

        &:focus,
        &:hover {
            background-color: $st;
            color: $white;
        }
    }
}

/** Main */
.nav-main {
    align-items: flex-end;
    padding: 0.5rem 1rem 0;
    text-transform: uppercase;

    .navbar-brand {
        margin: 0;
        padding: 0 0 0.5rem;
    }

    .navbar-toggler {
        @extend .btn;
        @extend .btn-primary;

        margin-bottom: 1rem;
    }

    .navbar-nav {
        margin-bottom: 0.5rem;
        margin-left: auto;
    }

    .nav-link {
        color: $st;
        position: relative;

        @include media-breakpoint-down(lg) {
            padding-left: 1rem;
        }

        &::before {
            background-color: $rd;
            border-radius: 3px;
            content: "";
            height: 0;
            left: 0;
            position: absolute;
            top: 50%;
            transition-property: height left top width;
            transition-duration: 0.2s;
            transition-timing-function: ease;
            width: 3px;


            @include media-breakpoint-up(lg) {
                bottom: 0;
                height: 2px;
                left: 50%;
                top: auto;
                width: 0;
            }
        }

        &:focus::before,
        &:hover::before {
            @include media-breakpoint-down(lg) {
                height: 100%;
                top: 0;
            }

            @include media-breakpoint-up(lg) {
                left: 0;
                width: 100%;
            }
        }
    }
}

/** Pagination */
.nav-pagination {
    margin-top: 3rem;

    .pagination {
        justify-content: center;
        margin: 0;
    }
}

/** Login / Register */
.nav-login,
.nav-register {
    margin-bottom: 1rem;

    .nav {
        flex-direction: column;
    }

    .nav-item {
        width: 100%;

        + .nav-item {
            margin-top: 1rem;
        }
    }

    .btn {
        text-align: left;
        width: 100%;
    }

    .btn-st .fa-fw {
        color: $st;
    }
}

/** Register */
.nav-register {
    margin-left: auto;
    margin-right: auto;
    max-width: 25rem;
}

/** Course */
.nav-course {
    margin-top: 2rem;

    .tab-content {
        padding: 1rem;
    }

    .list-unstyled li + li {
        margin-top: 0.5rem;
    }
}

.nav-classroom {
    .row {
        align-items: center;
    }

    .e-progress {
        margin-top: 0;
    }
}

/** Checkout */
.nav-checkout {

    .tab-content {
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        border-color: $gray-300;
        border-style: solid;
        border-top-style: none;
        border-width: 1px;
        padding-bottom: 1rem;
    }

    .tab-pane {
        padding: 1rem;
    }
}

.nav-filter {
    margin-top: 1rem;

    .nav {
        align-items: center;
        justify-content: flex-end;
    }
}

.nav-payment {
    margin-top: 1rem;

    .nav-label {
        @extend .btn;

        color: $white;
        cursor: default;

        &:focus,
        &:hover {
            color: $white;
        }
    }

    .nav-link {
        cursor: pointer;
    }

    .tab-content .tab-image {
        display: block;
        margin: 1rem auto;
    }
}
