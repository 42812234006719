.sidebar {
    margin: 0 auto 1rem;

    .list-group-divider {
        background-color: $light;
        color: $st;
    }
}

.s-checkout,
.s-group,
.s-report,
.s-user {
    max-width: 18.75rem;
    width: 18.75rem;
}
