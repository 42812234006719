.btn-st {
    @extend .btn-primary;
}

.btn-outline-st {
    @extend .btn-outline-primary;
}

.btn-nd {
    @extend .btn-secondary;

    color: $white;

    &:focus,
    &:hover {
        color: $white;
    }
}

.btn-outline-nd {
    @extend .btn-outline-secondary;

    background-color: $white;
}

.btn-white {
    color: $white;

    &:focus,
    &:hover {
        color: $gray-300;
    }
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-login {
    align-items: center;
    display: flex;
    text-align: left;

    + .btn-login {
        margin-top: 1rem;
    }

    .fa-fw {
        border-radius: 1rem;
        background-color: $white;
        height: 1.5rem;
        line-height: 1.125rem;
        margin-right: 1rem;
        padding: 0.2rem;
        vertical-align: middle;
        width: 1.5rem;
    }
}

.btn-facebook {
    background-color: $fb;
    color: $white;

    &:focus,
    &:hover {
        background-color: darken($fb, 10%);
        color: $white;
    }

    .fa-fw {
        color: $fb;
    }

}

.btn-google {
    background-color: $go;
    color: $white;

    &:focus,
    &:hover {
        background-color: darken($go, 10%);
        color: $white;
    }

    .fa-fw {
        color: $go;
    }
}

.btn-progress {

    .fa-check-circle {
        color: $success;
    }

    .fa-times-circle {
        color: $danger;
    }

    &:focus,
    &:hover {

        .fa-check-circle,
        .fa-times-circle {
            color: $white;
        }
    }
}
