.form {
    margin: 0 auto;
    max-width: 40.625rem;
    width: 100%;

    .card {
        border-color: $st;
    }

    .card-header {
        background-color: $st;
    }

    .card-body {
        padding-top: 0;
    }
}

.input-group {

    .form-control {
        background-color: $white;
        border-color: $st;
    }
}

.form-group {
    border-color: $st;
    border-radius: $border-radius;
    border-style: solid;
    border-width: 1px;
    margin-top: 2rem;
    padding: 0.5rem 0 0;
    position: relative;

    .form-group-footer {
        border-top-color: $st;
        border-top-style: solid;
        border-top-width: 1px;
        padding: 0.5rem 0.75rem;
        text-align: right;
    }

    .form-label {
        background-color: $white;
        color: $st;
        display: inline-block;
        left: 0.5rem;
        margin: 0;
        padding: 0 0.25rem;
        position: absolute;
        top: 0;
        transform: translateY(-70%)
    }

    .form-control {
        background-color: transparent;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-style: none;
        margin: 0;
        padding: 0.5rem 0.75rem;

        + .form-control {
            border-top-color: $st;
            border-top-style: solid;
            border-top-width: 1px;
        }

        &:focus {
            box-shadow: none;
        }
    }

    .form-list {
        list-style-type: none;
        margin: 0;
        padding: 0.5rem 0.75rem;
    }

    .input-group-text {
        border-style: none;
        color: $st;
        background-color: $white;
    }

    .tox-tinymce {
        border-radius: $border-radius;
        border-style: none;
    }

    .tox-toolbar__group {
        border-right-style: none !important;
    }

    .tox-statusbar__branding {
        display: none;
    }
}

.form-control-upload {

    .figure {
        position: relative;

        .figure-img {
            margin: 0;
            max-height: 12rem;
            max-width: 12rem;
        }

        .fa-paperclip {
            color: $gray-600;
            font-size: 5rem;
            margin: 1rem;
        }

        .btn {
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .uploaded.active {
        padding: 0.5rem;
    }

    .form-group-footer {
        border-style: none;
    }
}

.form-submit {
    text-align: right;
    margin-top: 2rem;
}

/** Chosen */
.chosen-container {
    box-shadow: none !important;

    .chosen-choices,
    .chosen-single {
        @extend .form-control;

        background: $white !important;
        box-shadow: none !important;
        height: auto !important;

        div {
            display: none;
        }
    }

    .search-choice {
        background-color: $white !important;
        background-image: none !important;
    }

    .search-choice-close {
        color: $white !important;
    }

    .chosen-drop {
        border-color: $st !important;
        margin-left: -1px;
        margin-right: -2px;
        width: calc(100% + 2px);
    }

    .chosen-search-input {
        border-color: $st !important;
        border-radius: $border-radius !important;
        padding: 0.375rem 0.75rem !important;
    }

    .chosen-results {

        .active-result {
            border-radius: $border-radius !important;
            padding: 0.375rem 0.75rem !important;
        }

        .highlighted {
            background: $st !important;
        }
    }
}
