.e-listing {
    @extend .row;

    list-style: none;
    padding-left: 0;
}

* + .e-listing {
    margin-top: 2rem;
}

.e-listing-item {
    @extend .col-sm-6;
    @extend .col-lg-4;
    @extend .col-xl-3;
}

.e-listing-chat {

    .e-listing-item {
        min-width: 100% !important;

        + .e-listing-item {
            margin-top: 1rem;
        }
    }
}

.e-listing-gallery {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 0;
    padding: 0;

    .e-listing-item {
        width: 50%;
    }
}

/** article */
.e-listing-article {

    .e-listing-item {
        @extend .col-sm-12;
        @extend .col-lg-6;
    }
}
