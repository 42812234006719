.print {
    background-color: rgb(204,204,204);
}

.certificate {
    background-color: $white;
    box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
    display: block;
    margin: 0 auto;
    margin-bottom: 0.5cm;
    position: relative;

    .background {
        height: 100%;
        position: absolute;
        width: 100%;
    }

    .content {
        height: 100%;
        padding: 3cm 2.5cm;
        position: relative;
        text-align: center;

        .header h1 {
            color: #256D83;
            font-size: 2.5rem;
            font-weight: 700;
        }

        .name {
            color: #256D83;
            display: block;
            font-size: 2rem;
            margin: 1rem auto;
        }

        .body {
            margin: 3cm auto;
            max-width: 20cm;
        }

        .footer {
            bottom: 3cm;
            left: 0;
            padding: 0 2.5cm;
            position: absolute;
            width: 100%;

            .author {
                float: left;
                font-weight: 700;
                text-align: center;

                img {
                    margin: 0 auto;
                }

                .text {
                    display: block;
                }
            }

            .brand {
                float: right;
                margin-left: auto;
            }

            img {
                margin: 0 0.5rem;
                max-height: 5rem;
                max-width: 5rem;
                vertical-align: middle;
            }

        }
    }

    .toolbar {
        display: flex;
        justify-content: center;
        left: 0;
        position: fixed;
        top: 2rem;
        width: 100%;
    }

    &.a4 {
        height: 29.7cm;
        width: 21cm;
    }

    &.landscape {
        height: 21cm;
        width: 29.7cm;
    }
}

@media print {

    @page {
        size: landscape;
        margin: 0;
    }

    .certificate {
        box-shadow: none;
    }

    .not-print,
    .not-print * {
        display: none !important;
    }
}
