.w-step {
    padding: 0 0 2rem;
    text-align: center;

    .fa-solid{
        font-size: 2em;
    }

    .fa-check-circle {
        color: $success;
    }

    .fa-minus-circle {
        color: $gray-500;
    }

    .txt {
        display: block;
        margin-top: 0.5em;
    }
}
