.w-chat {
    padding: 0;
    position: fixed;
    z-index: 12;

    .w-header {
        margin: 0;
    }

    .w-content {
        align-items: flex-end;
        bottom: 0;
        display: flex;
        flex-direction: row-reverse;
        max-width: 100%;
        position: fixed;
        overflow-x: auto;
        right: 0;
    }

    .e-thumb {
        border-width: 2px;
        display: inline-block;
        height: 2rem;
        margin: 0 1rem;
        min-width: 2rem;
        padding: 0;
        width: 2rem;
    }

    .offcanvas {
        @extend .offcanvas-end;

        backdrop-filter: blur(10px);
        background-color: rgba($white, 0.5);
        border-left-color: $st;
        border-left-style: solid;
        border-left-width: 2px;

        .offcanvas-header {
            background-color: transparent;
            color: $st;
            padding-bottom: 1rem;

            .btn {
                color: $st;
            }
        }

        .offcanvas-title {
            font-weight: 200;
        }
    }

    .offcanvas-backdrop {
        background-color: transparent;
    }
}
