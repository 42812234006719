.tab {
    margin-top: 2rem;

    .nav {
        justify-content: center;
    }

    .tab-content {
        padding: 1rem 0;

        li {
            margin-top: 0.5rem;
        }

        .e-content li {
            margin-top: 0;
        }
    }
}
