/*
.card {
    border-color: $nd;
}

* + .card {
    margin-top: 1rem;
}

.card {
    border-radius: 1rem;
}

.card-header {
    align-items: center;
    background-color: $rd;
    border-radius: 1rem !important;
    display: flex;
    justify-content: space-between;
    //margin: -1rem 0.5rem 0;

    .e-title {
        color: $white;
        font-weight: 200;
    }
}

.card-body {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    overflow: hidden;

    .table {
        margin-top: 0;
    }
}

.card-footer {
    background-color: transparent;
    text-align: right;
}
*/

.card-form {
    border-radius: 1rem;
    border-color: $st;

    .card-header {
        background-color: $st;
    }

    .nav-link {
        color: $white;

        &.active {
            color: $st;
        }
    }
}

// Chat
.card-chat {
    backdrop-filter: blur(10px);
    background-color: rgba($white, 0.8);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-style: none;
    height: 22rem;
    margin: 0 0.5rem;
    min-width: 20rem;
    overflow: hidden;
    transition-duration: 0.2s;
    transition-property: all;
    transition-timing-function: ease;
    width: 20rem;

    .card-title {
        margin: 0;
    }

    &.expand {
        height: 38rem;
        min-width: 30rem;
        width: 30rem;

        .btn-chat-expand {
            display: none;
        }

        .btn-chat-compress {
            display: inline-block;
        }

        .ratio {
            margin: 0 auto;
            width: 21rem;
        }
    }

    &.card-chat-users {
        .e-message {
            padding-left: 1rem;

            .e-thumb {
                display: none;
            }
        }
    }

    .btn-chat-expand {
        display: inline-block;

    }

    .btn-chat-compress {
        display: none;
    }

    .card-header {
        background-color: rgba($nd, 0.7);
        border-radius: 0 !important;
        align-items: center;
        display: flex;
        padding: 0.5rem 0;

        .btn-group {
            align-items: flex-start;

            .btn {
                border-radius: 0;
                color: $white;
            }
        }
    }

    .card-title {
        font-size: 1rem;
        flex-grow: 1;
    }

    .card-body {
        background-size: cover;
        flex-grow: 1;
        overflow-y: auto;
        padding: 1rem 0;
    }

    .card-footer {
        background-color: $white;
        padding: 0;
        text-align: left;

        .btn {
            color: $nd;
        }

        .input-group {
            border-bottom-style: none;
        }

        .messager {
            max-height: 5.25rem;
            resize: none;
        }

        .message-link {
            background-color: rgba($nd, 0.1);
            border-radius: 0.3rem;
            display: none;
            font-size: 0.875rem;
            font-style: italic;
            margin: 0.5rem;
            padding: 0.5rem;
            position: relative;

            &.active {
                display: block;
            }

            .btn {
                color: $nd;
                font-size: 0.875rem;
                position: absolute;
                right: 0.3rem;
                top: 0.3rem;

                &:focus,
                &:hover {
                    text-decoration: none;
                }
            }

            .img-thumbnail {
                max-height: 12.5rem;
                max-width: 12.5rem;
            }

            .fa-file-upload {
                font-size: 4em;
            }
        }

    }
}

.card-order {
    border-color: $st;
    margin: 0 auto;
    max-width: 40rem;

    .card-header {
        background-color: $st;
        border-bottom-color: $st;
        color: $white;
        font-weight: 300;
    }

    .card-body + .card-body {
        border-top-color: $st;
        border-top-style: solid;
        border-top-width: 1px;
    }

    .card-footer {
        background-color: $white;
        border-top-color: $st;
        color: $st;
        font-size: 1.25rem;
        text-align: right;
    }

    .card {
        text-align: center;

        .payload {
            padding: 0.5rem;
            display: block;
            word-break: break-all;
        }
    }
}
