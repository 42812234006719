.e-price {
    font-size: 1.25rem;
    font-weight: 300;
    text-align: right;

    .price,
    .disount {
        display: block;
    }

    .slash {
        font-size: 1rem;
        text-decoration: line-through;
    }

    .price.slash {
        color: $danger;
    }

    .discount {
        color: $success;
    }
}
