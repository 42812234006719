.modal-backdrop {
    backdrop-filter: blur(10px);
    background-color: rgba($white, 0.5);

    &.show {
        opacity: 1 !important;
    }
}

.modal-content {
    border-color: $st;
    border-radius: 1rem;
}

.modal-header {
    border-bottom-style: none;
}

.modal-title {
    color: $st;
    font-size: 1.5rem;
    font-weight: 300;
}

.modal-footer {
    border-top-style: none;
}

.modal-chat {

    .modal-footer,
    .modal-body:hover ~ .modal-footer {
        opacity: 1;
    }

    .modal-footer {
        border: none;
        bottom: 0;
        opacity: 0;
        position: absolute;
        padding: 2rem;
        right: 0;
        transition-duration: 0.2s;
        transition-property: opoacity;
        transition-timing-function: ease;
    }
}

/** Carousel */
.carousel-modal {
    .carousel-item img {
        display: block;
        margin: 0 auto;
    }
}
