.w-vcard {
    border-color: $rd;
    border-radius: $border-radius;
    border-style: solid;
    border-width: 2px;
    margin: 0 auto;
    max-width: 35rem;
    overflow: hidden;
    padding: 0 0 1rem;

    .w-header {
        background-color: $rd;
        padding: 0.5rem 1rem;
    }

    .w-title {
        color: $white;
    }

    .w-content {
        margin-top: 0;
    }

    .qr-code {
        border-color: $dark;
        border-style: solid;
        border-width: 1px;
    }

    .row {
        align-items: center;
        justify-content: center;

        .w-col {
            margin: 1rem auto 0;
        }
    }
}
