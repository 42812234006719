.p-content {
    padding: 2rem 0;
}

.p-content-group {
    .e-content {
        max-width: none;
    }
}

.e-content-empty {
    font-size: 1.5rem;
    font-weight: 200;
    text-align: center;

    .far {
        color: $danger;
        font-size: 5rem;
    }
}
