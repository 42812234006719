.w-progress {
    padding: 2rem 0;

    .row {
        justify-content: center;
        text-align: center;
    }

    .btn {
        margin-top: 1rem;
    }
}
