.e-price-total {

    .row {
        align-items: flex-end;
    }

    .value {
        font-size: 1.25rem;
        font-weight: 300;
        text-align: right;
    }

    .price:not(.price.slash) {
        font-weight: 700;
    }

    .price.slash {
        color: $danger;
        font-size: 1rem;
        text-decoration: line-through;
    }

    .discount {
        color: $success;
    }

    .total {
        font-weight: 700;
    }
}
