.app {
    min-height: 100%;
    overflow: hidden;
}

.app-header {
    position:relative;
    z-index: 10;
}

.app-main {
    min-height: 40rem;

    .with-sidebar {
        display: flex;
        justify-content: flex-start;
    }

    .main-content {
        flex-grow: 1;
    }
}

.app-footer {
    background-color: $gray-200;
    color: $rd;
    padding-top: 4rem;

    h2 {
        color: darken($st, 30%);
        margin-bottom: 1rem;
    }

    a {
        color: $rd;

        &:focus,
        &:hover {
            color: $rd-dark;
            text-decoration: underline;
        }
    }

    @include media-breakpoint-down(md) {
        .f-col + .f-col {
            margin-top: 2rem;
        }
    }

    .nav-social {
        font-size: 2em;

        .nav-link {
            padding: 0;
        }
    }

    .nav-appmap {
        .nav {
            flex-direction: column;
            padding-left: 1rem;
        }

        .nav-link {
            padding: 0.2em 0;
            transition-duration: 0.2s;
            transition-property: margin;
            transition-timing-function: ease;

            &::before {
                content: "\f0da";
                font-family: "Font Awesome 5 Free";
            }

            &:focus,
            &:hover {
                margin-left: 0.5em;
                text-decoration: none;
            }
        }
    }

    .copyright {
        background-color: $gray-300;
        color: $rd;
        font-size: 0.875rem;
        margin-top: 2rem;
        padding: 0.5em 0;
        text-align: center;

        span {
            display: block;
        }
    }
}
