.e-button {
    margin-top: 1rem;

    .btn-block + .btn-block {
        margin-top: 1rem;
    }
}

.e-button-cart {
    margin-top: 1rem;

    .btn-group {
        display: flex;
        justify-content: center;
    }

    .btn {
        flex: 0 1 auto;
    }
}

.e-button-continue {
    margin-top: 1rem;

    .btn {
        width: 100%;
    }
}
